/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo, useRef, useState, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import { TextureLoader } from 'three'
import Button from './Button'
import { useLoader } from '@react-three/fiber'
import { Html } from '@react-three/drei';

export function Ground2(props) {

  const texture = useLoader(TextureLoader, [
    './img/cift/neos.jpg',
    './img/cift/parlin.jpg',
    './img/cift/marsala.jpg',
    './img/cift/mihver.jpg',
    './img/cift/perez.jpg',
    './img/cift/sarah.jpg',
    './img/cift/melos.jpg',
    './img/cift/orville.jpg',
    './img/cift/blush.jpg',
    './img/cift/elementantrasit.jpg',
    './img/cift/eclipse.jpg',
    './img/cift/celerina.jpg',
  ])



  const group = useRef();
  const [selected, setSelected] = useState(0)
  const { nodes, materials, animations } = useGLTF("/neostotal.glb");

    // Metalness değerini ayarla
    useEffect(() => {
      if (materials.yastikon) {
        materials.yastikon.metalness = 0; // yastikon malzemesinin metalness değerini 0 olarak ayarla
      }
      // Diğer malzemeler için de benzer işlemler yapılabilir
    }, [materials]);
  const materialOverrides = useMemo(() => {
    return {
      0: materials.yorganon,
      1: materials.yorganon2,
      2: materials.yorganon3,
      3: materials.yorganon4,
      4: materials.yorganon5,
      5: materials.yorganon6,
      6: materials.yorganon7,
      7: materials.yorganon8,
      8: materials.yorganon9,
      9: materials.yorganon10,
      10: materials.yorganon11,
      11: materials.yorganon12,
    }
  }, [materials])

  const materialOverrides2 = useMemo(() => {
    return {
      0: materials.yorganarka,
      1: materials.yorganarka2,
      2: materials.yorganarka3,
      3: materials.yorganarka4,
      4: materials.yorganarka5,
      5: materials.yorganarka6,
      6: materials.yorganarka7,
      7: materials.yorganarka8,
      8: materials.yorganarka9,
      9: materials.yorganarka10,
      10:materials.yorganarka11,
      11:materials.yorganarka12,
     
    }
  }, [materials])

  const materialOverrides3 = useMemo(() => {
    return {
      0: materials.yastikonarka,
      1: materials.yastikonarka2,
      2: materials.yastikonarka3,
      3: materials.yastikonarka4,
      4: materials.yastikonarka5,
      5: materials.yastikonarka6,
      6: materials.yastikonarka7,
      7: materials.yastikonarka8,
      8: materials.yastikonarka9,
      9: materials.yastikonarka10,
      10:materials.yastikonarka11,
      11:materials.yastikonarka12,
      
    }
  }, [materials])

  const materialOverrides4 = useMemo(() => {
    return {
      0: materials.yastikon,
      1: materials.yastikon2,
      2: materials.yastikon3,
      3: materials.yastikon4,
      4: materials.yastikon5,
      5: materials.yastikon6,
      6: materials.yastikon7,
      7: materials.yastikon8,
      8: materials.yastikon9,
      9: materials.yastikon10,
      10:materials.yastikon11,
      11:materials.yastikon12,
    }
  }, [materials])

   
  return (
    <>
    <Html    position={[4.5, 0.4, -1.92]}>
    <div className="overlay">
        <button id="button7"   onClick={() => setSelected(0)} >
          neos
        </button>
      </div>

      <div className="overlay">
        <button id="button8"   onClick={() => setSelected(1)}>
         parlin
        </button>

        <div className="overlay">
        <button id="button9"   onClick={() => setSelected(2)}>
         marsala
        </button>
      </div>

      <div className="overlay">
        <button id="button10"   onClick={() => setSelected(3)}>
         mihver
        </button>
      </div>
      </div>

      <div className="overlay">
        <button id="button11"   onClick={() => setSelected(4)}>
         perez
        </button>
      </div>

      <div className="overlay">
        <button id="button12"   onClick={() => setSelected(5)}>
         sarah
        </button>
      </div>

      <div className="overlay">
        <button id="button13"   onClick={() => setSelected(6)}>
         melos
        </button>
      </div>

      <div className="overlay">
        <button id="button14"   onClick={() => setSelected(7)}>
         orville
        </button>
      </div>

      <div className="overlay">
        <button id="button15"   onClick={() => setSelected(8)}>
         blush
        </button>
      </div>

      <div className="overlay">
        <button id="button16"   onClick={() => setSelected(9)}>
        elementantrasit
        </button>
      </div>

  

      <div className="overlay">
        <button id="button17"   onClick={() => setSelected(10)}>
        eclipse
        </button>
      </div>

      <div className="overlay">
        <button id="button18"   onClick={() => setSelected(11)}>
        celerina
        </button>
      </div>
     
      </Html>

 <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object004.geometry}
        material={materials["Material.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.yorganarka.geometry}
        material={materialOverrides2[selected]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.yorganon.geometry}
        material={materialOverrides[selected]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.yastikarka.geometry}
        material={materialOverrides2[selected]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.yastikon.geometry}
        material={materialOverrides4[selected]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.yastikonarka.geometry}
        material={materialOverrides3[selected]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object004006.geometry}
        material={materials["Material.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object004007.geometry}
        material={materials.ictahta}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object004008.geometry}
        material={materials.tahta}
      />
      <group position={[-0.4938, -0.1708, -0.2065]} scale={-0.1781}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.parlin_1.geometry}
          material={materials.yastikarka2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.parlin_2.geometry}
          material={materials.yorganon2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.parlin_3.geometry}
          material={materials.yorganarka2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.parlin_4.geometry}
          material={materials.yastikonarka2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.parlin_5.geometry}
          material={materials.yastikon2}
        />
      </group>
      <group scale={0.1806}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane.geometry}
          material={materials.yorganon3}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane_1.geometry}
          material={materials.yorganarka3}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane_2.geometry}
          material={materials.yastikonarka3}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane_3.geometry}
          material={materials.yastikon3}
        />
      </group>
      <group position={[0, 0, -0.3895]} scale={-0.2243}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane002.geometry}
          material={materials.yorganon4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane002_1.geometry}
          material={materials.yorganarka4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane002_2.geometry}
          material={materials.yastikon4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane002_3.geometry}
          material={materials.yastikonarka4}
        />
      </group>
      <group position={[0, 0, 0.4643]} scale={0.1626}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001.geometry}
          material={materials.yorganon5}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_1.geometry}
          material={materials.yorganarka5}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_2.geometry}
          material={materials.yastikon5}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_3.geometry}
          material={materials.yastikonarka5}
        />
      </group>
      <group position={[0, 0, 0.8418]} scale={0.1013}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane003.geometry}
          material={materials.yorganon6}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane003_1.geometry}
          material={materials.yorganarka6}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane003_2.geometry}
          material={materials.yastikon6}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane003_3.geometry}
          material={materials.yastikonarka6}
        />
      </group>
      <group position={[0.322, 0, 0.8021]} scale={-0.1178}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004.geometry}
          material={materials.yorganon7}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004_1.geometry}
          material={materials.yorganarka7}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004_2.geometry}
          material={materials.yastikon7}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004_3.geometry}
          material={materials.yastikonarka7}
        />
      </group>
      <group position={[0.3796, 0, 0.5864]} scale={0.07}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005.geometry}
          material={materials.yorganon8}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005_1.geometry}
          material={materials.yorganarka8}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005_2.geometry}
          material={materials.yastikon8}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005_3.geometry}
          material={materials.yastikonarka8}
        />
      </group>
      <group position={[0.4715, 0, 0]} scale={0.0992}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane006.geometry}
          material={materials.yorganon9}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane006_1.geometry}
          material={materials.yorganarka9}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane006_2.geometry}
          material={materials.yastikon9}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane006_3.geometry}
          material={materials.yastikonarka9}
        />
      </group>
      <group position={[0.4454, 0, -0.2989]} scale={-0.0699}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane007.geometry}
          material={materials.yorganon10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane007_1.geometry}
          material={materials.yorganarka10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane007_2.geometry}
          material={materials.yastikon10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane007_3.geometry}
          material={materials.yastikonarka10}
        />
      </group>
      <group position={[0.4365, 0, -0.5236]} scale={-0.0768}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane008.geometry}
          material={materials.yorganon11}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane008_1.geometry}
          material={materials.yorganarka11}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane008_2.geometry}
          material={materials.yastikon11}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane008_3.geometry}
          material={materials.yastikonarka11}
        />
      </group>
      <group position={[0.4514, 0, -0.7351]} scale={0.0678}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane009.geometry}
          material={materials.yorganon12}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane009_1.geometry}
          material={materials.yorganarka12}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane009_2.geometry}
          material={materials.yastikon12}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane009_3.geometry}
          material={materials.yastikonarka12}
        />
      </group>
    </group>
    </>
  );

}
useGLTF.preload("/neostotal.glb");