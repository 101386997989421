/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo, useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { TextureLoader } from 'three'
import Button from './Button'
import { useLoader } from '@react-three/fiber'
import { Html } from '@react-three/drei';

export function Ground8(props) {

  const texture = useLoader(TextureLoader, [
    './img/valeria/tiffany.jpg',
    './img/valeria/yorgonon1.jpg',
    './img/valeria/rosegold.jpg',
    './img/valeria/gul.jpg',
    './img/valeria/gul.jpg',
    './img/valeria/gul.jpg',
    './img/valeria/gul.jpg',
    './img/valeria/gul.jpg',
  
  ])



  const group = useRef();
  const [selected, setSelected] = useState(0)
  const { nodes, materials, animations } = useGLTF("/mottotam.glb");
  const materialOverrides = useMemo(() => {
    return {
      0: materials.yorganic,
      1: materials.yorganic2,
      2: materials.yorganic3,
      3: materials.yorganic4,
      4: materials.yorganic5,
      5: materials.yorganic6,
      6: materials.yorganic7,
      7: materials.yorganic8,
    }
  }, [materials])

  const materialOverrides2 = useMemo(() => {
    return {
      0: materials.logo,
      1: materials.logo2,
      2: materials.logo3,
      3: materials.logo4,
      4: materials.logo5,
      5: materials.logo6,
      6: materials.logo7,
      7: materials.logo8,
    }
  }, [materials])

  const materialOverrides3 = useMemo(() => {
    return {
      0: materials.etiket,
      1: materials.etiket2,
      2: materials.etiket3,
      3: materials.etiket4,
      4: materials.etiket5,
      5: materials.etiket6,
      6: materials.etiket7,
      7: materials.etiket8,
    }
  }, [materials])



  return (
    <>

<Html    position={[3, 1.4, 4]}>
    <div className="overlay">
        <button id="button38"   onClick={() => setSelected(0)} >
          indigo
        </button>
      </div>

      <div className="overlay">
        <button id="button39"   onClick={() => setSelected(1)}>
      bej
        </button>

        <div className="overlay">
        <button id="button40"   onClick={() => setSelected(2)}>
       antrasit
        </button>
      </div>

      <div className="overlay">
        <button id="button41"   onClick={() => setSelected(3)}>
        gri
        </button>
      </div>
      </div>

      <div className="overlay">
        <button id="button42"   onClick={() => setSelected(4)}>
         kahve
        </button>
      </div>

      <div className="overlay">
        <button id="button43"   onClick={() => setSelected(5)}>
         mercan
        </button>
      </div>

      <div className="overlay">
        <button id="button44"   onClick={() => setSelected(6)}>
        hardal
        </button>
      </div>

      <div className="overlay">
        <button id="button45"   onClick={() => setSelected(7)}>
        petrol
        </button>
      </div>

    
     
      </Html>

 <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Pattern_43483.geometry}
        material={materialOverrides[selected]}
        position={[-0.13782, 0.08584, 0]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.00521}
      />
      <group
        position={[-0.13781, 0.0869, -0.00272]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={0.00521}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Pattern_43483001_1.geometry}
          material={materials.etiketust}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Pattern_43483001_2.geometry}
          material={materials.etiketalt}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Pattern_22271003.geometry}
        material={materialOverrides2[selected]}
        position={[0.3391, 0.85792, -0.46929]}
        rotation={[1.65796, 0, Math.PI / 2]}
        scale={0.00521}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Pattern_43483002.geometry}
        material={materialOverrides3[selected]}
        position={[0.53671, 0.70345, 1.02182]}
        rotation={[1.57928, 0, Math.PI / 2]}
        scale={0.00521}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object762001.geometry}
        material={materials["3355.001"]}
        position={[0.02904, 0.60548, 0.16783]}
        scale={[0.00081, 0.001, 0.00096]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cone136001.geometry}
        material={materials["Plastic_Leather_D01_Black_10cm #0.001"]}
        position={[0.5979, 0, 21.35666]}
        scale={[0.00081, 0.001, 0.00096]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane.geometry}
        material={materials["Material.002"]}
        position={[0.56788, 0.66366, 1.06442]}
        scale={0.00921}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text.geometry}
        material={materials.designedforcottoncomfort}
        position={[0.53794, 0.69129, 1.0691]}
        rotation={[0.9049, 0.07228, -0.14125]}
        scale={0.00635}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001.geometry}
        material={materials.designedforcottoncomfort}
        position={[0.53732, 0.69134, 1.06924]}
        rotation={[0.8462, 0.09214, -0.22656]}
        scale={[0.09008, 0.04809, 0.0076]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cone136002.geometry}
        material={materials["Plastic_Leather_D01_Black_10cm #0.001"]}
        position={[0.5979, 0, 19.5342]}
        scale={[0.00081, 0.001, 0.00096]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.baza005001.geometry}
        material={materials["baza.001"]}
        position={[0.02654, 0.253, 1.04748]}
      />
      <group position={[0, 0.09908, 0.07494]} scale={0.0973}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_1.geometry}
          material={materials.yorganic2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_2.geometry}
          material={materials.etiket2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_3.geometry}
          material={materials.logo2}
        />
      </group>
      <group position={[0.40329, 0, 0.15692]} scale={0.10889}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004.geometry}
          material={materials.yorganic3}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004_1.geometry}
          material={materials.logo3}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004_2.geometry}
          material={materials.etiket3}
        />
      </group>
      <group position={[0, 0, 0.51622]} scale={0.11205}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005.geometry}
          material={materials.yorganic4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005_1.geometry}
          material={materials.logo4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005_2.geometry}
          material={materials.etiket4}
        />
      </group>
      <group position={[0, 0, -0.09425]} scale={-0.0983}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane006.geometry}
          material={materials.yorganic5}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane006_1.geometry}
          material={materials.logo5}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane006_2.geometry}
          material={materials.etiket5}
        />
      </group>
      <group position={[0.39412, 0, 0.51201]} scale={-0.09982}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane007.geometry}
          material={materials.yorganic6}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane007_1.geometry}
          material={materials.logo6}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane007_2.geometry}
          material={materials.etiket6}
        />
      </group>
      <group position={[0.41835, 0, -0.10176]} scale={0.06045}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane008.geometry}
          material={materials.yorganic7}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane008_1.geometry}
          material={materials.logo7}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane008_2.geometry}
          material={materials.etiket7}
        />
      </group>
      <group position={[-0.26104, 0, 0.34053]} scale={-0.0512}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane009.geometry}
          material={materials.yorganic8}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane009_1.geometry}
          material={materials.logo8}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane009_2.geometry}
          material={materials.etiket8}
        />
      </group>
    </group>
    </>
  );
}

useGLTF.preload("/mottotam.glb");