/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo, useRef, useState } from "react";
import { useGLTF, Html } from "@react-three/drei";
import { TextureLoader } from 'three'
import Button from './Button'
import { useLoader } from '@react-three/fiber'


export function Ground5(props) {

  const texture = useLoader(TextureLoader, [
    './img/smart/raspberry.png',
    './img/smart/blueberry.png',
    './img/smart/lemon.png',
    './img/smart/orange.png',
  
  ])



  const group = useRef();
  const [selected, setSelected] = useState(0)
  const { nodes, materials } = useGLTF("/smartcomfort.glb");
  const materialOverrides = useMemo(() => {
    return {
      0: materials.yorganon,
      1: materials.yorganon2,
      2: materials.yorganon3,
      3: materials.yorganon4,
     
    }
  }, [materials])

  const materialOverrides2 = useMemo(() => {
    return {
      0: materials.yorganarka,
      1: materials.yorganarka2,
      2: materials.yorganarka3,
      3: materials.yorganarka4,
     
    }
  }, [materials])

  const materialOverrides3 = useMemo(() => {
    return {
      0: materials.etiket,
      1: materials.etiket2,
      2: materials.etiket3,
      3: materials.etiket4,
    }
  }, [materials])



  return (
    <>

<Html    position={[0, 1.5, -5]}>
    <div className="overlay">
        <button id="button22"   onClick={() => setSelected(0)} >
        raspberry
        </button>
      </div>

      <div className="overlay">
        <button id="button23"   onClick={() => setSelected(1)}>
        blueberry
        </button>

        <div className="overlay">
        <button id="button24"   onClick={() => setSelected(2)}>
       lemon
        </button>
      </div>

      <div className="overlay">
        <button id="button25"   onClick={() => setSelected(3)}>
       orange
        </button>
      </div>
      </div>


     
   
      </Html>
 <group {...props} dispose={null}>
      <group position={[-0.046, -0.125, -0.212]} scale={0.012}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.yastik1ic.geometry}
          material={materials.yorganon}
          position={[0.26, 0, -9.689]}
          scale={[1.226, 1.056, 0.799]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.yastik2ic.geometry}
          material={materials.yorganon}
          position={[0.26, 0, -9.689]}
          scale={[1.226, 1.056, 0.799]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.yorganarka.geometry}
          material={materialOverrides2[selected]}
          position={[0.26, 0, -9.689]}
          scale={[1.226, 1.056, 0.799]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.yorganon.geometry}
          material={materialOverrides[selected]}
          position={[0.26, 0, -9.689]}
          scale={[1.226, 1.056, 0.799]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.StitchMatShape_1037900_Node.geometry}
        material={materials.yorganon}
        position={[-0.043, -0.125, -0.324]}
        scale={[0.014, 0.012, 0.009]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.StitchMatShape_1037900_Node001.geometry}
        material={materials.yorganon}
        position={[-0.043, -0.125, -0.324]}
        scale={[0.014, 0.012, 0.009]}
      />
      <group position={[0.087, -0.103, -0.639]} scale={0.012}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.etiket.geometry}
          material={materialOverrides3[selected]}
          position={[51.474, 19.549, 144.097]}
          rotation={[0.021, 0.178, -0.026]}
          scale={1.01}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.StitchMatShape_1031478_Node.geometry}
          material={materials["Material439520.002"]}
          position={[51.533, 19.515, 144.022]}
          rotation={[0.021, 0.178, -0.026]}
          scale={1.01}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane.geometry}
        material={materialOverrides2[selected]}
        position={[0.013, 0.105, 0.05]}
        scale={[0.74, 0.74, 0.835]}
      />
      <group scale={0.221}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001.geometry}
          material={materials.yorganon2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_1.geometry}
          material={materials.yorganarka2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_2.geometry}
          material={nodes.Plane001_2.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_3.geometry}
          material={materials.etiket2}
        />
      </group>
      <group position={[0, 0, 0.537]} scale={-0.218}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane002.geometry}
          material={materials.yorganon3}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane002_1.geometry}
          material={materials.yorganarka3}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane002_2.geometry}
          material={materials.etiket3}
        />
      </group>
      <group position={[0, 0, -0.457]} scale={0.207}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane003.geometry}
          material={materials.yorganon4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane003_1.geometry}
          material={materials.yorganarka4}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane003_2.geometry}
          material={materials.etiket4}
        />
      </group>
    </group>
    </>
  );
}

useGLTF.preload("/smartcomfort.glb");